import React from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

import Cookies from "../components/cookies"

import logo from "../images/logo-signet.svg"
import Facebook from "../images/icons/facebook.svg"
import Twitter from "../images/icons/twitter.svg"
import Linkedin from "../images/icons/linkedin.svg"
import YouTube from "../images/icons/youtube.svg"
import Instagram from "../images/icons/instagram.svg"

const Footer = () => (
  <footer className="footer pad-b-6">
    <div className="container">
      <div className="columns is-multiline">
        <div className="column is-one-third-tablet is-2-desktop">
          <Link to="/">
            <img src={logo} alt="ExpertSender" width="80" height="70" />
          </Link>
          <div className="mar-t-3">
            <a href="https://www.facebook.com/ExpertSenderBrasil/" target="_blank" rel="noopener noreferrer" className="footer__follow" title="Facebook">
              <Facebook />
            </a>
            <a href="https://twitter.com/ExpertSender_BR" target="_blank" rel="noopener noreferrer" className="footer__follow" title="Twitter">
              <Twitter />
            </a>
            <a href="https://www.linkedin.com/company/expertsender-brasil/" target="_blank" rel="noopener noreferrer" className="footer__follow" title="Linkedin">
              <Linkedin />
            </a>
            <a href="https://www.youtube.com/channel/UCe7UlSnX0PijdEWzA4hcrzg?sub_confirmation=1" target="_blank" rel="noopener noreferrer" className="footer__follow" title="YouTube">
              <YouTube />
            </a>
            <a href="https://www.instagram.com/expertsenderbrasil/" target="_blank" rel="noopener noreferrer" className="footer__follow" title="Instagram">
              <Instagram />
            </a>
          </div>
        </div>
        <div className="column is-one-third-tablet is-2-desktop">
          <div className="footer__header">
            <FormattedMessage id="navbar.i_want_to" />
          </div>
          <nav className="footer__nav">
            <ul>
              <li>
                <Link to="/i-want-to/sell-more">
                  <FormattedMessage id="navbar.sell_more" />
                </Link>
              </li>
              <li>
                <Link to="/i-want-to/keep-customers-coming-back">
                  <FormattedMessage id="navbar.keep_customers" />
                </Link>
              </li>
              <li>
                <Link to="/i-want-to/distribute-my-content">
                  <FormattedMessage id="navbar.distribute_content" />
                </Link>
              </li>
              <li>
                <Link to="/i-want-to/sell-directly-to-customers-d2c">
                  <FormattedMessage id="navbar.sell_directly" />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="column is-one-third-tablet is-2-desktop">
          <div className="footer__header">
            <FormattedMessage id="navbar.success_stories" />
          </div>
          <nav className="footer__nav">
            <ul>
              <li>
                <Link to="/success-stories/ecommerce">
                  <FormattedMessage id="navbar.ecommerce" />
                </Link>
              </li>
              <li>
                <Link to="/success-stories/travel-and-hospitality">
                  <FormattedMessage id="navbar.travel_and_hospitality" />
                </Link>
              </li>
              <li>
                <Link to="/success-stories/finance-and-banking">
                  <FormattedMessage id="navbar.finance_and_banking" />
                </Link>
              </li>
              <li>
                <Link to="/success-stories/automotive">
                  <FormattedMessage id="navbar.automotive" />
                </Link>
              </li>
              <li>
                <Link to="/success-stories/media-and-publishing">
                  <FormattedMessage id="navbar.media_and_publishing" />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="column is-one-third-tablet is-2-desktop">
          <div className="footer__header">
            <FormattedMessage id="navbar.why_expertsender" />
          </div>
          <nav className="footer__nav">
            <ul>
              <li>
                <Link to="/why-expertsender/experience">
                  <FormattedMessage id="navbar.experience" />
                </Link>
              </li>
              <li>
                <Link to="/why-expertsender/values">
                  <FormattedMessage id="navbar.values" />
                </Link>
              </li>
              <li>
                <Link to="/why-expertsender/team">
                  <FormattedMessage id="navbar.team" />
                </Link>
              </li>
              <li>
                <Link to="/why-expertsender/security">
                  <FormattedMessage id="navbar.security" />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="column is-one-third-tablet is-2-desktop">
          <div className="footer__header">
            <FormattedMessage id="navbar.contact" />
          </div>
          <nav className="footer__nav">
            <ul>
              <li>
                <a href="/demo/">
                  <FormattedMessage id="navbar.request_demo" />
                </a>
              </li>
              <li>
                <a href="/contact-us/">
                  <FormattedMessage id="navbar.contact_us" />
                </a>
              </li>
              <li>
                <a href="https://help.expertsender.com" rel="noopener" target="_blank">
                  帮助
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="footer__copy mar-t-3">
        <div className="columns">
          <div className="column is-narrow">
            <span className="footer__copy__item">&copy; {new Date().getFullYear()} ExpertSender</span>
          </div>
          <div className="column is-narrow">
            <Link to="/privacy-policy" className="footer__copy__item">
              <FormattedMessage id="footer.privacy_policy" />
            </Link>
          </div>
          <div className="column is-narrow">
            <Link to="https://beian.miit.gov.cn/" className="footer__copy__item">
            京ICP备13035093号-1
            </Link>
          </div>
        </div>
      </div>
    </div>
    
    <Cookies />
  </footer>
)

export default Footer
