import PropTypes from "prop-types"
import React from "react"

import Navbar from "../components/navbar"

const Header = ({ version }) => (
  <header className="header">
    <Navbar version="{version}" />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
