import React, { Component } from "react"
import { Link } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-intl"

class Cookies extends Component {
  setCookieInfo () {
    if (typeof localStorage !== 'undefined') {
      document.getElementById('cookie-info').remove();
      localStorage.setItem('cookie_info', 1);
    }
  }

  componentDidMount () {
    if (typeof localStorage !== 'undefined') {
      if (!localStorage.getItem('cookie_info')) {
        document.getElementById('cookie-info').classList.remove('is-hidden');
        document.getElementById('cookie-info').classList.add('is-visible');
      }
    }
  }

  render () {    
    return (
      <div id="cookie-info" className="cookies is-hidden">
        <p>Nous utilisons des cookies pour optimiser votre expérience sur le site Web. En visitant notre site, vous consentez à cette utilisation. Pour plus d'informations, veuillez consulter notre <Link to="/privacy-policy">Politique de confidentialité</Link>.</p>
        <button className="cookies__button button is-link" onClick={this.setCookieInfo} onKeyDown={this.setCookieInfo}>
          OK
        </button>
      </div>
    )
  }
};

export default Cookies
